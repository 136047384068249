<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose">
      <div class="video-box">
        <video id="video" controlslist="nodownload" controls v-if="type == 1">
          <source :src="dialogPath" controlslist="nodownload" type="video/mp4">
          您的浏览器不支持视频播放
        </video>
        <el-image
          v-if="type == 0"
          style="width: 600px; height: 400px"
          :src="dialogPath"
          fit="contain">
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="handleClose">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: [ 'dialogPath', 'dialogVisible', 'type'],
  data () {
    return {
      // dialogVisible: true
    }
  },
  computed: {
    title() {
      return this.type == 1? '视频预览' : '图片预览'
    }
  },
  methods: {
    handleClose () {
      this.$emit('handleClose')
    }
  }
}
</script>

<style scoped>
  .video-box {
    width: 600px;
    max-height: 400px;
    margin: 0 auto;
    text-align: center;
  }
  #video {
    width: 600px;
    height: 400px;
  }
</style>
