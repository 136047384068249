<template>
  <div id="app">
    <div class="home-head-box">
      <img src="./assets/kll_logo.png" alt="logo">
      <span class="home-span">凯丽隆官网</span>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import index from './views/Index/index'

export default {
  components: { index },
  data() {
    return {
      equipment: 'pc'
    }
  },
  watch: {
    $route(val) {
      if (val.query.isChild) return
      this.initData()
    }
  },
  mounted() {
    document.oncontextmenu = function () {
      return false
    }
    document.onkeydown = function (e) {
      if (e && e.keyCode === 123) {
        e.returnValue = false
        return false
      }
    }
  },
  created() {
    //判断当前设备为pc还是移动端
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      this.equipment = 'phone'
    } else {
      this.equipment = 'pc'
    }
    // this.initData()
  },
  methods: {
    initData() {
      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (this.$route.query.shareID) {
        // localStorage.setItem('route', path)
        localStorage.setItem('key', this.$route.query.shareID)
      }
      let shareID = localStorage.getItem('key')
      if (this.$route.query.shareID) {
        this.$router.push({
          path: this.$route.path,
          query: {
            shareID,
          }
        }).catch(erro => {
          // console.log('地址发生变化')
        })
        loading.close()
      } else {
        this.$router.push({
          path: '/no_data',
        })
        loading.close()
      }
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  padding-top: 90px;
  background: #f7f8fa;
}
.home-head-box {
  width: 100%;
  height: 70px;
  display: flex;
  background: #14141d;
  position: fixed;
  top: 0;
  z-index: 99;
}
.home-head-box img {
  width: 210px;
  height: 50px;
  margin-top: 10px;
}
.home-span {
  height: 60px;
  padding-left: 30px;
  line-height: 70px;
  font-size: 25px;
  font-family: 新宋体;
  font-weight: bold;
  color: white;
}
</style>
