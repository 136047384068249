import request from '../request/requerst'

export function getList(data) {
  return request({
    url: '/share/'+ data.path +'/lists',
    method: 'post',
    data
  })
}

export function projectDocsDetail(data) {
  return request({
    url: '/share/project_docs/detail',
    method: 'post',
    data
  })
}
