import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:'/no_data',
    component: ()=> import('../views/notKey/notKey')
  },
  {
    path:'/shareMaterial',
    component: ()=> import('../views/Index/index')
  },
  {
    path:'/shareMaterial_compete',
    component: ()=> import('../views/compete/index')
  },
  {
    path:'/shareDocs',
    component: ()=> import('../views/Docs/index'),
  },
  {
    path:'/detail',
    component: ()=> import('../views/Docs/detail')
  },
  {
    path:'/shareActor',
    component: ()=> import('../views/actors/index')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next)=> {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})

export default router
